// --- Vendors ---
// --- Components ---
const header = {
    contactUrl: '',

    readyFn: function () {
        let self = this;

        $('.hide-des').on('click', function () {
            $("#menu-mobile").slideToggle();
            $("body").delay('200').toggleClass("menu_actif");
        });

        // AJOUT DU PADDING SUR LE HEADER   ---
        let headerHeight = document.getElementById('sticky-header').offsetHeight;
        $("header").css('height', headerHeight);


        // AJOUT CLASS SI ON SCROLL LA PAGE
        var position = $("header").scrollTop();
        var windowScrollTop = 0;
        $(window).on('scroll', function () {
            windowScrollTop = $(window).scrollTop() - 200;
            if (windowScrollTop > position) {
                document.getElementsByTagName('header')[0].classList.add('scrolled');
            } else {
                document.getElementsByTagName('header')[0].classList.remove('scrolled');
            }
        });

        //SUB-MENU
        $(".header-menu-mobile .menu-item-has-children").click(function () {
            $(this).toggleClass("menu-item-has-children-active");
            $(".sub-menu", this).slideToggle();
        });

        $("header .menu-item-has-children").hover(function () {
            $(".sub-menu").removeClass("actif");
            $(".sub-menu", this).addClass("actif");
        });

        $("header").hover(function () { },
            function () {
                $(".sub-menu", this).removeClass("actif");
            });

        $(".label-burger-menu").on("click", function () {
            $("#container-menu-mobile").toggleClass("actif");
            $("#sticky-header").toggleClass("actif");
            $("header").toggleClass("menu-actif");
        });

        $("input.burger-menu").on("click", function () {
            if ($(this).hasClass("actif")) {
                $("input.burger-menu").prop("checked", false);
            } else {
                $("input.burger-menu").prop("checked", true);
            }

            $("input.burger-menu").toggleClass("actif");
        });

        $("#container-menu-mobile .menu-item-has-children").on("click", function () {
            $(".sub-menu", this).slideToggle();
            $(this).toggleClass("actif");
        });

    },
}
export { header };