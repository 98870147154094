// --- Vendors ---
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

// --- Components ---
const homePage = {

    readyFn: function () {
        let self = this;
        this.swiper();
        this.prestation();

    },

    swiper: function () {
        const swiper = new Swiper('.swiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            breakpoints: {
                // when window width is >= 320px
                1200: {
                    slidesPerView: 2,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

    },

    prestation: function () {

        let BiggerCell = 0;
        let cellContenus = $("#prestation .cell-contenu");
        for (let i = 0; i < cellContenus.length; i++) {
            if (cellContenus[i].offsetHeight > BiggerCell) {
                BiggerCell = cellContenus[i].offsetHeight;
            }
        }
        $("#prestation .cell-contenu").css("min-height", BiggerCell);
        $("#prestation .cell-contenu").addClass("non-actif");

        $("#prestation .cell-carre").on("click", function () {
            let data = $(this).attr("data");
            $("#prestation .cell-contenu").removeClass("actif");
            $("#prestation .cell-carre").removeClass("actif");
            $(this).addClass("actif");
            $(data).addClass("actif");
        });
    },


}
export { homePage };